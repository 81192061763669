<template>
  <div>
    <div>
      <v-select
        v-model="selectedReport"
        :options="reports"
        placeholder="Relatórios"
        class="w-50"
      >
      </v-select>
      <b-card v-if="selected">
        <RelatorioConformidade v-if="selectedReport.value == 'conformidade'" />
        <RelatorioLastro v-if="selectedReport.value == 'lastro'" />
        <RelatorioConcentracaoSacado v-if="selectedReport.value == 'concentracao_sacado'" />
      </b-card>
      <b-card v-else>
        <h3>Selecione um relatorio para exibir</h3>
      </b-card>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import http from '/src/services/http'
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import HtmlRenderer from '../../../common/components/HtmlRenderer.vue';
import RelatorioConformidade from '../components/RelatorioConformidade.vue';
import RelatorioLastro from '../components/RelatorioLastro.vue';
import RelatorioConcentracaoSacado from '../components/RelatorioConcentracaoSacado.vue';


export default {
  name: 'ReportsIndex',
  components: {
    BCard,
    vSelect,
    HtmlRenderer,
    RelatorioConformidade,
    RelatorioLastro,
    RelatorioConcentracaoSacado
},
  data() {
    return {
      componentPath: null,
      selected: false,
      errors: [],
      reports: [
        {
          value: 'concentracao_sacado',
          label: 'Relatório de concentração por sacado'
        },
        {
          value: 'conformidade',
          label: 'Relatório de conformidade', 
        },
        {
          value: 'lastro',
          label: 'Relatório de lastros'
        }
      ],
      selectedReport: { value: null, label: 'Relatorios' },
    }
  },
  methods: {
   
  },
  watch: {
    selectedReport: {
      handler(newValue, oldValue) {
        this.selected = false
        const selectedValue = newValue.value
        this.selected = !!this.reports.map((r) => r.value).includes(selectedValue)
      },
    },
  },
}
</script>

<style lang="scss">

.wr-icon {
  margin-bottom: 3px;
  margin-right: 12px;
  color: #F9A900;
}

.sc-icon {
  margin-bottom: 3px;
  margin-right: 12px;
  color: #237F52;
}

.v-select {
  width: 260px;
  background: white;
  margin-bottom: 28px;
  margin-left: auto;
  margin-top: -55px;
}

.remessa-link {
  color: #005387 !important;
}

.modalEspera {
  position: absolute;
  top: 30%;
  left: 35%;
  z-index: 9999;
  height: 10px;
}

.blur {
  width: 100%;
  height: 100%;
  z-index: 9998;
  backdrop-filter: blur(3px);
  position: absolute;
}
</style>
